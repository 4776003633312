import React from 'react'

const renderPreviousButton = () => {
    return (
        <svg width="46" height="23" viewBox="0 0 46 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0131 0.00756586C9.69164 0.0396933 9.3924 0.195393 9.1822 0.442526L0.319018 10.5652C-0.106339 11.0446 -0.106339 11.7663 0.319018 12.2457L9.1822 22.3684C9.64712 22.8898 10.4459 22.9368 10.9702 22.4722C11.492 22.01 11.5414 21.2118 11.0814 20.6879L4.058 12.6808H35.6331C36.2019 14.8531 38.1753 16.4768 40.5197 16.4768C43.3018 16.4768 45.5844 14.1957 45.5844 11.4155C45.5844 8.63523 43.3018 6.35415 40.5197 6.35415C38.1754 6.35415 36.2019 7.97782 35.6331 10.1501H4.058L11.0814 2.12287C11.44 1.73486 11.5192 1.16646 11.2818 0.694428C11.0419 0.224869 10.5374 -0.049463 10.0131 0.00741382L10.0131 0.00756586ZM40.5202 8.88452C41.9348 8.88452 43.0525 10.0016 43.0525 11.4152C43.0525 12.8288 41.9348 13.9459 40.5202 13.9459C39.1056 13.9459 37.9879 12.8288 37.9879 11.4152C37.9879 10.0016 39.1056 8.88452 40.5202 8.88452Z" fill="#3D3935" />
        </svg>
    )
}

const renderNextButton = () => {
    return (
        <svg width="46" height="23" viewBox="0 0 46 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.9869 0.00756586C36.3084 0.0396933 36.6076 0.195393 36.8178 0.442526L45.681 10.5652C46.1063 11.0446 46.1063 11.7663 45.681 12.2457L36.8178 22.3684C36.3529 22.8898 35.5541 22.9368 35.0298 22.4722C34.508 22.01 34.4586 21.2118 34.9186 20.6879L41.942 12.6808H10.3669C9.79813 14.8531 7.8247 16.4768 5.48026 16.4768C2.69817 16.4768 0.415584 14.1957 0.415584 11.4155C0.415584 8.63523 2.69817 6.35415 5.48026 6.35415C7.82463 6.35415 9.79809 7.97782 10.3669 10.1501H41.942L34.9186 2.12287C34.56 1.73486 34.4808 1.16646 34.7182 0.694428C34.9581 0.224869 35.4626 -0.049463 35.9869 0.00741382L35.9869 0.00756586ZM5.47981 8.88452C4.06524 8.88452 2.94747 10.0016 2.94747 11.4152C2.94747 12.8288 4.06524 13.9459 5.47981 13.9459C6.89437 13.9459 8.01214 12.8288 8.01214 11.4152C8.01214 10.0016 6.89437 8.88452 5.47981 8.88452Z" fill="#3D3935" />
        </svg>
    )
}
const SliderNavigation = React.forwardRef((props, swiperRef) => {
    return (
      
            <div className={"nav-wrapper"+ (props.mobile=="true" ? " nav-wrapper-mobie": "")}>
                <div className="custom-prev"
                    onClick={() => swiperRef?.current?.swiper?.slidePrev()}
                    role="button" aria-labelledby="Previous Slide">
                    {renderPreviousButton()}
                </div>
                <div className="custom-next"
                    onClick={() => swiperRef?.current?.swiper?.slideNext()}
                    role="button" aria-labelledby="Next Slide">
                    {renderNextButton()}
                </div>
            </div>
        
    )

})

export default SliderNavigation