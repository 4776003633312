import * as React from 'react'
import Getintouch from '../components/GetinTouch'
import LatestUpdates from '../components/LatestUpdates'
import Seo from '../components/seo'
import Layout from '../components/layout'
import InfoGraphics from '../components/InfoGraphics'
import DullMoment from '../components/DullMoment'
import SportsBoulevard from '../components/SportsBoulevard'
import WhoWeAre from '../components/WhoWeAre'
import Guidelines from '../components/Guidelines'
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import CICActivity from '../components/CICActivity'
import LatestCICActivities from '../components/LatestCICActivities'
export default function CICActivities(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri
 console.log(data)
    return (
        <>
        <Seo data={seo}/>
        <Layout translatedURI={translatedURI}>
        <div className="cic-activity-who-we-are">
          <WhoWeAre
            title={data.wpPage.pageSubtitle?.subtitle}
            name={data.wpPage?.title}
            description={data.wpPage.pageDescription?.pageDescription}
          />
        </div>
          <CICActivity data={data?.wpPage?.cicActivityPage}/>
          
          {(data?.allWpCicactivity?.edges.length>1)?
          <LatestCICActivities data={data}/>:''
          }
   
        </Layout>
        </>
    )
}

export const pageQuery = (graphql` query cicActivityData($databaseId:Int) {
  allWpCicactivity(sort: {fields: date, order: DESC}) {
      edges {
        node {
          language {
            id
            locale
          }
          id
          title
          content
          excerpt
          date(formatString: "DD-MM-YYYY")
          featuredImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
         
          slug
          uri
        }
      }
    }
    allWpTag {
      edges {
        node {
          id
          name
          language {
            id
            code
            locale
          }
        }
      }
    }
    
    allWpCategory {
      edges {
        node {
          id
          name
          language {
            id
            code
            locale
          }
        }
      }
    }
  wpPage(databaseId: {eq: $databaseId}) {
    id
    title
    pageSubtitle {
      fieldGroupName
      subtitle
    }
    content
    featuredImage {
      node {
        altText
        localFile {
          publicURL
        }
      }
    }
    pageDescription {
      pageDescription
    }
    cicActivityPage {
      file {
        localFile {
          publicURL
        }
      }
      image{
        altText
        localFile {
          publicURL
        }
      }
      title
      text
      mainSliderActivity {
        ... on WpCicactivity {
          id
          slug
          uri
          title
          excerpt
          content
          featuredImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
}`);