import React from "react"
import "./sports.scss"

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"
import { titleDesign } from "../../../utils"

import SliderNavigation from "../SharedComponents/SliderNavigation"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

import { createMarkup, string_translation } from "../../../utils"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

export default function CICActivity(props) {
  const swiperRef = React.useRef(null)
  const { data } = props

  const {
    locale,
    originalUrl, // ar
  } = useContext(TranslateUrlsContext)
  const { stringTranslations } = useStaticQuery(graphql`
    query {
      stringTranslations {
        downlaod_ar: translateString(language: AR, string: "تحميل")
        downlaod_en: translateString(language: EN, string: "Download")
      }
    }
  `)
  const shouldRenderArrows = data?.length > 1

  return (
    <>
      <section className="newsroom-first-block-cic">
        <div className="container">
      
          
                
                <div className="col-group">
                  <div className="col-dt-7">
                    <div className="img-wrapper">
                      <img  
                        src={props?.data?.image.localFile?.publicURL}
                        alt={props?.data?.image?.altText || props?.data?.title}
                      />
                    </div>
                  </div>
                  <div className="col-dt-5">
                    <div className="second-col-wrapper">
                      <div className="lifestyle-wrapper">
                        <div
                          className="lifestyle-title h2"
                          dangerouslySetInnerHTML={titleDesign(props?.data?.title)}
                        ></div>
                        <div
                          className="lifestyle-desc"
                          dangerouslySetInnerHTML={createMarkup(
                            (props?.data?.text)
                          )}
                        ></div>
                        <div className="read-the-story">
                          <div className="learn-more">
                        {  (props?.data?.file?.localFile?.publicURL!=null) ? (
                         <Link 
                    to={props?.data?.file?.localFile?.publicURL}
                    state={{ parenturl: originalUrl }}
                    className="download-btn"
                  >
                              {string_translation(
                                stringTranslations,
                                "downlaod",
                                locale
                              )}
                              </Link>
                        ) : ('')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
            
            <div className="col-group">
              <div className="col-dt-7"></div>
              <div className="col-dt-5">
                   &nbsp;
              </div>
            </div>
         
        </div>
      </section>
    </>
  )
}
